import { render, staticRenderFns } from "./VideoExample.vue?vue&type=template&id=6cd60374&scoped=true&"
import script from "./VideoExample.vue?vue&type=script&lang=js&"
export * from "./VideoExample.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd60374",
  null
  
)

export default component.exports